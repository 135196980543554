@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-indicator:before {
    content: '';
    background: #00000080;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.loading-indicator:after {
    content: ' ';
    position: fixed;
    top: 40%;
    left: 45%;
    z-index: 10010;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #0474bf;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.menu li>*:not(ul):not(.menu-title):not(details):active,
.menu li>*:not(ul):not(.menu-title):not(details).active,
.menu li>details>summary:active {
    background-color: #FFA400;
    color: white;

}


:where(.menu li:not(.menu-title):not(.disabled) > *:not(ul):not(details):not(.menu-title)):not(.active):hover,
:where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(.active):hover {
    background-color: #ffe5bb;
    color: black;
}

:where(.menu li:not(.menu-title):not(.disabled) > *:not(ul):not(details):not(.menu-title)):not(summary):not(.active).focus,
:where(.menu li:not(.menu-title):not(.disabled) > *:not(ul):not(details):not(.menu-title)):not(summary):not(.active):focus,
:where(.menu li:not(.menu-title):not(.disabled) > *:not(ul):not(details):not(.menu-title)):is(summary):not(.active):focus-visible,
:where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(summary):not(.active).focus,
:where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(summary):not(.active):focus,
:where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):is(summary):not(.active):focus-visible {
    background-color: #FFA400;
    color: white;
}

.bg-base-200 {
    --tw-bg-opacity: 1;
    background-color: #fff5e5;
}

.btn {
    background-color: #FFA400;
}

.btn:hover {
    background-color: #ffe5bb;
}

.error-message {
    color: red;
    font-size: small;
    padding-top: 0.5em;
}